import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    }
  },
  containerListItem: {
    marginTop: 80,
    maxWidth: "1080px",
    width: "100%",
    flexGrow: 1,
    padding: '0 16px',
    alignItems: 'center',
    justifyContent: "center",
    display: 'flex'
    // [theme.breakpoints.down('sm')]: {
    // }
  },
  containerBackground: {
    backgroundColor: "rgba(202, 223, 184, 0.5)",
    boxShadow: "4px 4px 12px 0px green",
    maxWidth: "1080px",
    width: "100%",
    borderRadius: 30,
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    gap: 30
  },
  logo: {
    width: 40,
    height: 40
  },
  containerInput: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    '& .list': {
      display: "flex",
      flexDirection: "column",
      width: '100%',
      gap: 5,
      '& p': {
        marginTop: 10,
        marginBottom: 10
      }
    },
    '& .listInput': {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: 5,
      width: "80%"
    }
  },
  containerButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
      marginTop: 10
    }
  }
}));
