// Chakra imports
import { Box, Grid, useColorModeValue } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";

// Assets
import banner from "assets/img/dailyvibe.png";
import logo from "assets/img/logo.png";
// import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect, useState } from "react";
import ServiceTable from "views/admin/profile/components/ServiceTable";
import { columnsDataService } from "views/admin/profile/variables/columnsData";
import tableDataService from "views/admin/profile/variables/tableDataService.json";
import axios from "axios";

export default function FriendDetail(props) {
  const {match} = props
  const [user, setUser] = useState();
  const [service, setService] = useState()
  const [loadingItems, setLoadingItems] = useState({}); 

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    let token = localStorage.getItem('token');
    if(localUser && token) {
      // setUser(JSON.parse(localUser))
      axios.get(`https://api.personatalenta.id/user/${props.match.params.type}/${props.match.params.id}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode",  'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }})
      .then(res => {
        setUser(res.data.user ?? res.data.colleagues)
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
    } else {
      window.location.assign('/home')
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/user/service/table-list-friend/${props.match.params.id}?is_has_user_id=${props.match.params.type === 'friend'}`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      if (res?.data?.products) {
        setService(res.data.products)
      }
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })

  }, [])

  const onClickDetail = props => {
    if (props.category === "File PDF" && props.name === "Standard Profiling Book") {
      window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/profiling-standard-web`)
    } else if (props.category === "File PDF" && props.name === "Complete Profiling Book") {
      window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/profiling-complete-web`)
    }
  }

  const onClickDownload = props => {
    const token = localStorage.getItem('token');
    setLoadingItems(prev => ({ ...prev, [props.index]: true })); // Set individual item loading
    let baseUrl;
    if (props.category === "File PDF" && props.name === "Standard Profiling Book") {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${user.id}&type=${match.params.type === 'friend' ? 0 : 1}&version=standard`
    } else if (props.category === "File PDF" && props.name === "Complete Profiling Book") {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${user.id}&type=${match.params.type === 'friend' ? 0 : 1}&version=complete`
    } else if (props.category === 'File PDF' && props.name === "Career Profiling Book") {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/career?type=${match.params.type === 'friend' ? 2 : 1}&user_id=${user.id}`
    } else if (props.category === 'File PDF' && props.name === 'Relationship Profiling Book') {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/relation?type=${match.params.type === 'friend' ? 2 : 1}&user_id=${user.id}`
    } else if (props.category === 'File PDF' && props.name === 'Workstyle Profiling Book') {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/workstyle?type=${match.params.type === 'friend' ? 2 : 1}&user_id=${user.id}`
    }

    axios.get(baseUrl, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}, responseType: 'blob'})
    .then(res => {
      if (res?.data) {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res.data);
        a.download = `${props.name === "Career Profiling Book" ? "Karir Terbaik Untuk" : props.name} ${user.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }).catch(err => {
      if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
        // navigate('/');
      }
    }).finally(() => {
      setLoadingItems(prev => ({ ...prev, [props.index]: false })); // Reset loading after completion
    });
  }

  const onClickCategory = props => {
    if (props.category === "Web View" && props.service === "webview") {
      window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/profiling-web`)
    } else if (props.category === "Web View" && props.service === "Persona") {
      window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/persona-web`)
    } else if (props.category === "Web View" && props.service === "matching") {
      window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/matching-web`)
    }
  }

  const onClickBuy = props => {
    //dashboard/friend-list/colleague/78/checkout/12
    window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/checkout/${props.id}`)
    // if (props.service === "profiling") {
    //   window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/product/profiling-book`)
    // } else if (props.service === "webview") {
    //   window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/product/profiling-web`)
    // } else if (props.service === "Persona" && props.name === "General Persona") {
    //   window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/product/persona`)
    // } else if (props.service === "Persona" && props.name === "Daily Vibe Persona") {
    //   window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/product/daily-vibe`)
    // } else if (props.service === "matching") {
    //   window.location.assign(`/dashboard/friend-list/${match.params.type}/${user?.id}/product/matching`)
    // }
  }
  const onClickPrice = props => {
    if (props.service === "profiling" || props.service === "webview") {
      window.location.assign(`/pricing#profiling?type=${match.params.type}&id=${user?.id}`)
    } else if (props.service === "Persona" || props.name === "General Persona" || props.name === "Daily Vibe") {
      window.location.assign(`/pricing#persona?type=${match.params.type}&id=${user?.id}`)
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
    } else if (props.service === "matching") {
      window.location.assign(`/pricing#matching?type=${match.params.type}&id=${user?.id}`)
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
    }
  }

  // const onClickPrice = props => {
  //   if (props.service === "profiling" || props.service === "webview") {
  //     window.location.assign(`/service#1`)
  //   } else if (props.service === "Persona" || props.name === "General Persona" || props.name === "Daily Vibe") {
  //     window.location.assign(`/service#2`)
  //   //   window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
  //   } else if (props.service === "Persona" && props.name === "Daily Vibe") {
  //   //   window.location.assign(`/dashboard/profile/${user?.username}/product/daily-vibe`)
  //   } else if (props.service === "matching") {
  //     window.location.assign(`/service#3`)
  //   //   window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
  //   }
  // }


  return (
    <Box pt={{ sm: 0, base: "130px", md: "80px", xl: "80px" }} mt={{sm: 5, md: 10}}>
      {/* Main Fields */}
      <Grid
        // templateColumns={{
        //   base: "1fr",
        //   lg: "1.34fr 1fr 1.62fr",
        // }}
        // templateRows={{
        //   base: "repeat(3, 1fr)",
        //   lg: "1fr",
        // }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
          edit={false}
        />
        <ServiceTable
          columnsData={columnsDataService}
          tableData={service}
          onClickCategory={onClickCategory}
          onClickBuy={onClickBuy}  
          onClickPrice={onClickPrice}
          onClickDetail={onClickDetail}
          onClickDownload={onClickDownload}
          loading={loadingItems}
          user={user}
          friend={true}
        />
      </Grid>
    </Box>
  );
}
