// Chakra imports
import { Box, Grid, useColorModeValue } from "@chakra-ui/react";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Information from "views/admin/profile/components/Information";

// Assets
import banner from "assets/img/dailyvibe.png";
import logo from "assets/img/logo.png";
// import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect, useState } from "react";
import Upload from "./components/Upload";
import ServiceTable from "./components/ServiceTable";
import { columnsDataService } from "./variables/columnsData";
import { CircularProgress, SnackbarContent } from "@mui/material";
import axios from "axios";

export default function Overview() {
  const [user, setUser] = useState();
  const [service, setService] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingItems, setLoadingItems] = useState({});

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
    } else {
      window.location.assign('/home')
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/user/service/table-list`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
      .then(res => {
        if (res?.data?.products) {
          setService(res.data.products)
        }
      }).catch(err => {
        if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
          // navigate('/');
        }
      })
  }, [])

  const onClickCategory = props => {
    console.log(props)
    if (props.category === "Web View" && props.service === "webview") {
      window.location.assign(`/dashboard/profile/${user?.username}/profiling-web`)
    } else if (props.category === "Web View" && props.service === "persona") {
      window.location.assign(`/dashboard/profile/${user?.username}/persona-web`)
    } else if (props.category === "Web View" && props.service === "matching") {
      window.location.assign(`/matching`)
    }
  }

  const onClickDetail = props => {
    console.log(props)
    if (props.category === "File PDF" && props.name === "Standard Profiling Book") {
      window.location.assign(`/dashboard/profile/${user?.username}/profiling-standard-web`)
    } else if (props.category === "File PDF" && props.name === "Complete Profiling Book") {
      window.location.assign(`/dashboard/profile/${user?.username}/profiling-complete-web`)
    }
  }

  const onClickDownload = props => {
    setLoadingDownload(true)
    setLoadingItems(prev => ({ ...prev, [props.index]: true })); // Set individual item loading
    const token = localStorage.getItem('token');
    setLoading(true)
    let baseUrl;
    if (props.category === "File PDF" && props.name === "Standard Profiling Book") {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${user.id}&type=0&version=standard`
    } else if (props.category === "File PDF" && props.name === "Complete Profiling Book") {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${user.id}&type=0&version=complete`
    } else if (props.category === 'File PDF' && props.name === "Career Profiling Book") {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/career`
    } else if (props.category === 'File PDF' && props.name === 'Relationship Profiling Book') {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/relation`
    } else if (props.category === 'File PDF' && props.name === 'Workstyle Profiling Book') {
      baseUrl = `https://api.personatalenta.id/profiling/pdf/workstyle`
    }
    axios.get(baseUrl, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" }, responseType: 'blob' })
      .then(res => {
        setLoading(false)
        setLoadingDownload(false)
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res.data);
        a.download = `${props.name === "Career Profiling Book" ? "Karir Terbaik Untuk" : props.name} ${user.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }).catch(err => {
        setLoading(false)
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      }).finally(() => {
        setLoadingItems(prev => ({ ...prev, [props.index]: false })); // Reset loading after completion
      });
  }

  const onClickBuy = props => {
    console.log(props)
    window.location.assign(`/dashboard/profile/${user?.username}/checkout/${props.id}`)
    // if (props.service === "profiling") {
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/profiling-book`)
    // } else if (props.service === "webview") {
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/profiling-web`)
    // } else if (props.service === "Persona" && props.name === "General Persona") {
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
    // } else if (props.service === "Persona" && props.name === "Daily Vibe Persona") {
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/daily-vibe`)
    // } else if (props.service === "matching") {
    //   window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
    // }
  }
  const onClickPrice = props => {
    if (props.service === "profiling" || props.service === "webview") {
      window.location.assign(`/pricing#profiling`)
    } else if (props.service === "Persona" || props.name === "General Persona" || props.name === "Daily Vibe") {
      window.location.assign(`/pricing#persona`)
      //   window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
    } else if (props.service === "matching") {
      window.location.assign(`/pricing#matching`)
      //   window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
    }
  }

  const onClickEdit = () => {
    window.location.assign(`/dashboard/profile/${user?.username}/edit`)
  }

  const handleClose = () => {
    setLoadingDownload(false);
  }

  const action = (
    <div>
      <CircularProgress size={25} />
    </div>
  )

  return (
    <Box pt={{ sm: 0, base: "130px", md: "30px", xl: "30px" }}>
      <Grid
        gap={{ base: "20px", xl: "20px" }}
        mt={{ sm: 0, md: 10 }}>
        <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
          onClickEdit={onClickEdit}
        />
        <ServiceTable
          columnsData={columnsDataService}
          tableData={service}
          onClickCategory={onClickCategory}
          onClickBuy={onClickBuy}
          onClickPrice={onClickPrice}
          onClickDetail={onClickDetail}
          onClickDownload={onClickDownload}
          loading={loadingItems}
          user={user}
        />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={loadingDownload}
        onClose={handleClose}
      >
        <SnackbarContent style={{ height: 100 }}
          action={action}
          message={
            <div>
              <span id="client-snackbar">Saat ini dokumen sedang proses download, mohon ditunggu</span>
              <p>estimasi 1 menit</p>
            </div>
          }
        />
      </Snackbar>
    </Box>
  );
}
